import React, { useState } from "react";
import Lottie from "react-lottie";
import missionLottieData from "../data/lotties/mission.json"; // Update the path to your Lottie JSON file
import { FaCopy } from "react-icons/fa"; // Import Font Awesome Copy Icon

export const About = (props) => {
  const [copied, setCopied] = useState(false);
  const contractAddress = "F9wDiatUc2v4EL5ke7FYGjwRmLNsWGg7Jk9DigtHpump"; // Replace with the actual contract address

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 10000); // Reset the copied state after 10 seconds
    });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: missionLottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div id="about" style={{ backgroundColor: "#ffe591", padding: "20px" }}>
      {/* Contract Address Section */}
      <strong
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          marginBottom: "20px",
          padding: "2px 10px",
          borderRadius: "5px",
          color: "Black",
          fontSize: "4rem",
          fontFamily: "'Sour Gummy', serif",
        }}
      >
        CONTRACT ADDRESS:
      </strong>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          marginBottom: "20px",
          flexWrap: "wrap", // Allow wrapping to avoid overflow on smaller screens
        }}
      >
        <div
          className="alert alert-warning"
          role="alert"
          style={{
            borderRadius: "10px",
            display: "inline-flex", // Allow the content to shrink-wrap
            alignItems: "center", // Keep everything in a single row
            padding: "7px", // Padding for overall element
            overflow: "hidden", // Prevent overflow
          }}
        >
          <span
            style={{
              backgroundColor: "#ff6e6e", // Background color for "CA:"
              padding: "7px", // Padding for spacing
              borderRadius: "5px",
              color: "#fff", // Text color for "CA:"
              fontWeight: "bold",
              marginRight: "10px",
            }}
          >
            CA:
          </span>{" "}
          <span
            style={{
              color: "black", // Contract address color set to black
              fontFamily: "'Sour Gummy', serif", // Custom font-family
              fontSize: "18px", // Consistent font size for the address
              whiteSpace: "nowrap", // Prevent wrapping
              overflow: "hidden", // Hide overflow
              textOverflow: "ellipsis", // Truncate text with ellipsis
            }}
          >
            {contractAddress}
          </span>
          <span
            onClick={handleCopy}
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "10px",
              backgroundColor: "#ff6e6e",
              color: "#fff",
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#ff4747")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#ff6e6e")}
          >
            {copied ? (
              <span
                style={{
                  fontSize: "12px",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                ✔
              </span>
            ) : (
              <FaCopy size={14} color="#fff" />
            )}
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img
              src="img/putoto_cartoon_character.png"
              className="img-responsive"
              alt=""
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2 style={{ color: "#ff6e6e" }}>About</h2>
              <p style={{ color: "black", fontFamily: "Sour Gummy, serif" }}>
                {props.data ? props.data.paragraphAbout : "loading..."}
              </p>
              <div className="list-style"></div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2 style={{ color: "#ff6e6e" }}>
                Mission
                <div className="lottie-container">
                  <Lottie options={defaultOptions} height={100} width={100} />
                </div>
              </h2>
            </div>
            <p style={{ color: "black", fontFamily: "Sour Gummy, serif" }}>
              {props.data
                ? props.data.paragraphMission.split("\\n").map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))
                : "loading..."}
            </p>
            <div className="list-style"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
